<template>
  <h1>
    Oppsss!
  </h1>
  <h3>
    The {{ resource }} you're looking for is not here
  </h3>
  <router-link :to="{ name: 'EventList' }">Back to the home page</router-link>
</template>

<script>
export default {
  name: 'NotFound',

  props: {
    resource: {
      type: String,
      default: 'page'
    }
  },
}
</script>